import logo from "./orangemont-gradient.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="Orangemont" />
        <div>
          <span className="line">digital product development</span>
          <span className="line">&nbsp;|&nbsp;</span>
          <span className="line">drone photography</span>
        </div>
      </header>
    </div>
  );
}

export default App;
